@import 'assets/styles/constants.scss';

.navbar {
    background-color: $color-background-home-header;
    color: #fff;
    padding: 1em 0;
    display: flex;
    overflow: hidden;
}

.logo {
    max-height: 20rem;
    max-width: 20em;
    margin: -1rem 0;
    cursor: pointer;
}

.amz {
    font-size: 2rem;
}

.msp {
    text-transform: capitalize;
    color: $color-background-button-primary-hover;
    font-weight: bold;
    font-size: 1.5rem;
}

.links {
    text-decoration: none !important;
    font-size: 1.5rem;
    display: inline-block;
    text-align: center;

}

.active {
    color: $color-background-button-primary-hover !important;
}

.not-active {
    color: $color-background-control-disabled !important;
}

.brand {
    padding: 0 1.5rem 0 1.5rem;
}

.dropdownMenu {
    display: none;
    margin-left: auto;
    padding-right: 1rem;
}

.dropdown {
    float: left;
    overflow: hidden;
}

.dropdown .dropdownButton {
    border: none;
    outline: none;
    color: $color-background-control-disabled;
    text-decoration: none !important;
    font-size: 1.5rem;
    background-color: $color-background-home-header;
    margin: 0;
}

.dropdownContent {
    display: none;
    position: absolute;
    min-width: 160px;
    background-color: $color-background-home-header;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding-top: 1.6rem !important;
}

.dropdownContentHr {
    display: block;
    margin-top: 0em;
    margin-bottom: 0em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    color: $color-background-container-header !important;
}

.dropdownContentLink {
    float: none !important;
    text-decoration: none !important;
    font-size: 1.5rem;
    display: block;
    text-align: left;
    padding: 1.5rem 2rem !important;
    background-color: #fff;
    color: #000 !important;
}

.dropdown:hover .dropdownContent {
    display: block;
}

.flexGap {
    flex-grow: 1;
}


